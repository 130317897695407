import { createRouter, createWebHistory } from "vue-router";

const routesInit = [
    { path: '/', redirect: '/login' },
    { path: '/login', name: "login", component: () => import('@/views/audition/index.vue') },
    // { path: '/login', name: "login", component: () => import('@/views/login.vue') },
    { path: '/frontHome', name: "frontHome", component: () => import('@/views/frontHome.vue'), children: [], meta: { requiresAuth: true } },
    { path: '/awIndex', name: "awIndex", component: () => import('@/views/home.vue'), children: [
        {path: '/two', name: "two", component: () => import('@/views/ai/2d.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/thr', name: "thr", component: () => import('@/views/ai/thr.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/ai', name: "ai", component: () => import('@/views/ai/index.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/meeting', name: "meeting", component: () => import('@/views/ai/meeting.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/dialog', name: "dialog", component: () => import('@/views/ai/dialog.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/menus', name: "menus", component: () => import('@/views/common/menus/index.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/modules', name: "modules", component: () => import('@/views/common/modules/index.vue'),children: [], meta: { requiresAuth: true }},
        { path: '/product', name: "product",component: () => import('@/views/common/product/index.vue'), icon: "Menu", meta: { requiresAuth: true } },
        { path: '/role', name: "role",component: () => import('@/views/common/role/index.vue'), icon: "Menu", meta: { requiresAuth: true } },
        {path: '/aiIndex', name: "aiIndex", component: () => import('@/views/ai/aiIndex.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/user', name: "user", component: () => import('@/views/common/user/index.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/aiInfo', name: "aiInfo", component: () => import('@/views/ai/aiInfo.vue'),children: [], meta: { requiresAuth: true }},
        {path: '/space', name: "space", component: () => import('@/views/common/space/index.vue'),children: [], meta: { requiresAuth: true }},
    ], meta: { requiresAuth: true } },
    { path: '/404', component: () => import('@/components/404.vue') },
    // { path: '/:catchAll(.*)', redirect: '/404' },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routesInit,
    hasLoadedDynamicRoutes: false
});


function checkAccess(to, next) {
    const token = window.sessionStorage.getItem('token');
    if (token) {
        //检查用户角色 todo
        // const requiresRoles=router.meta.requiresRoles;
        return next();;
    }
    return next('/');
}


function handleGuard(to, from, next) {
    console.log('12')
    return next()
    const matchedRouter = router.getRoutes().find(r => r.path === to.path);
    if (matchedRouter) {
        // if (matchedRouter.meta.requiresAuth) {
        return checkAccess(to, next);
        // } else {
        //     return next();
        // }
    } else {
        //获取缓存routes的信息
        const routeData = JSON.parse(window.sessionStorage.getItem('routes'));
        if (!routeData || routeData.length === 0) {
            return next('/404');
        }
        //获取全部的路由
        const allRoutesData = JSON.parse(window.sessionStorage.getItem('allRoutes'));
        if (!allRoutesData || allRoutesData.length === 0) {
            return next('/404');
        }
        const saveRoute = allRoutesData.find(r => r.path === to.path);
        if (saveRoute) {
            if (saveRoute.path !== to.path) {
                return checkAccess(to, next);
            } else {
                return next(false);
            }
        } else {
            return next('/404');
        }
    }
}

router.beforeEach(handleGuard);

export function addRoutesRecursively(routes, parent = null) {
    routes.forEach(router => {
        router.children = router.children || [];
        const routeComponent = () => import(`@/views/${router.component}`).catch(() => {
            return import('@/components/404.vue');
        })

        const routeCofig = {
            path: router.path,
            name: router.name,
            component: routeComponent,
            meta: { requiresAuth: router.meta },
            children: router.children
        }

        if (parent == 'frontHome') {
            router.options.routes[2].children.unshift(routeCofig);
            router.addRoute('frontHome', routeCofig);
        } else {
            router.options.routes[2].children.unshift(routeCofig);
            router.addRoute('frontHome', routeCofig);
        }
        if (router.children && router.children.length) {
            addRoutesRecursively(router.children, parent);
        }
    });

    window.sessionStorage.setItem('routes', JSON.stringify(routes));
    window.sessionStorage.setItem('allRoutes', JSON.stringify(router.getRoutes()));
}

export function loadRoutesSessionStrorag(){
    const saveRoute=JSON.parse(window.sessionStorage.getItem('routes'))
    const currentSystem=JSON.parse(window.sessionStorage.getItem('system_roleList'))
    if(saveRoute){
        addRoutesRecursively(saveRoute,currentSystem)
    }

}

export default router