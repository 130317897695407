<template>
  <div class="block">
    <el-pagination
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      :page-sizes="[10, 20, 50, 100]"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
    />
  </div>
</template>
<script>
export default {
  name: "pagination",
  props: {
    total: {
      required: true,
      type: Number,
      default: 0,
    },
    currentPage: {
      required: true,
      type: Number,
      default: 1,
    },
    pageSize: {
      required: true,
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSizeChange(pageSize) {
      this.$emit("update:pageSize", pageSize);
      this.$emit("size-change", pageSize);
    },
    handleCurrentChange(currentPage) {
      this.$emit("update:currentPage", currentPage);
      this.$emit("current-change", currentPage);
    },
  },
};
</script>

<style scoped>

.block {
  bottom: 20px;
  text-align: right;
  right: 20px;
  display: flex;
  margin-right: 80%;
  justify-content: flex-start !important;
  align-items: flex-end !important;
}
</style>
