export const messages={
    zh:{
        
        role:{
            name:'角色名称',
        }
    },
    en:{
        role:{
            name:'RoleName',
        }
    },
    jp:{
        role:{
            name:'役割名',
        }
    }
}