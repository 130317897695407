import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import axios from 'axios'
//注册icons的图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Shanghai')


import { createI18n } from 'vue-i18n'
// 分页组件
import Pagination from "@/components/pagination";
import {loadRoutesSessionStrorag} from './router/index.js'

loadRoutesSessionStrorag();

//虚拟滚动
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
// import VueVirtualScroller from 'vue-virtual-scroller'
// Vue.use(VueVirtualScroller)




import zhCn from "element-plus/es/locale/lang/zh-cn";

import {messages} from './locale/locale'
const app = createApp(App);
app.component('Pagination',Pagination)

app.use(store)
app.use(ElementPlus, {locale: zhCn});
app.use(router);

const i18n = createI18n({
    locale: 'zh', // 设置地区
    messages, // 引入翻译文件
  });
  


app.use(i18n)

app.config.globalProperties.$axios = axios;

app.mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}