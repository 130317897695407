import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
        };
    },
    mutations: {
        // this.$store.commit('setToken',setToken)
    },
    actions: {
        // this.$store.dispatch("login", data);
    },
    getters: {
        // this.$store.getters.getToken
    }
});

export default store;